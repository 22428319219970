<template>
  <b-card
      class="user-edit-wrapper"
  >
    <!-- form -->
    <b-form id="userForm" class="edit-form mt-2">
      <!-- Media -->
      <b-media class="mb-2">
        <template #aside>
          <b-avatar
              ref="previewEl"
              :src="get_upload_url(user.avatar)"
              :text="avatarText(user.full_name)"
              :variant="`light-${getCodeColor('role', user.role_id) || 'primary'}`"
              size="90px"
              rounded
          />
        </template>

        <b-media-body class="mt-75 ml-75">
          <div class="d-flex flex-wrap">
            <b-button
                variant="outline-primary"
                @click="$refs.refInputEl.click()"
                size="sm"
            >
              <input
                  ref="refInputEl"
                  type="file"
                  class="d-none"
                  @input="changeAvatar($event)"
              >
              <span class="d-none d-sm-inline">上传</span>
              <feather-icon
                  icon="EditIcon"
                  class="d-inline d-sm-none"
              />
            </b-button>
          </div>
          <b-card-text>JPG、PNG等图片格式</b-card-text>
        </b-media-body>
      </b-media>

      <b-row>
        <!--        <b-col md="3">-->
        <!--          <b-form-group-->
        <!--            label-cols="3"-->
        <!--            label-cols-lg="3"-->
        <!--            label="公司ID"-->
        <!--            label-for="company_id"-->
        <!--            label-size="sm"-->
        <!--            class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--              id="company_id"-->
        <!--              size="sm"-->
        <!--              v-model="user.company_id"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="部门"
              label-for="deptartment_id"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                id="deptartment_id"
                :options="getCodeOptions('department')"
                v-model="departmentName"
                :clearable="true"
                class="select-size-sm"
                @input="setDepartmentName($event)"
                placeholder="请选择部门名称"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="登录名"
              label-for="username"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="username"
                size="sm"
                v-model="user.username"
            />
          </b-form-group>
        </b-col>
        <b-col md="3"
               class="hidden"
        >
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="密码"
              label-for="password"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="password"
                size="sm"
                v-model="user.password"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="真实姓名"
              label-for="full_name"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="full_name"
                size="sm"
                v-model="user.full_name"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="手机号"
              label-for="mobile"
              label-size="sm"
              class="mb-1 required"
          >
            <b-form-input
                id="mobile"
                size="sm"
                v-model="user.mobile"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="邮件"
              label-for="email"
              label-size="sm"
              :class="'mb-1 '+(user.deptartment_id == 8 ?'':'required')"
          >
            <b-form-input
                id="email"
                size="sm"
                v-model="user.email"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="工号"
              label-for="job_num"
              label-size="sm"
              :class="'mb-1 '+(user.deptartment_id == 8 ?'':'required')"
          >
            <b-form-input
                id="job_num"
                size="sm"
                @change="setOneJobNum()"
                v-model="user.job_num"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="角色"
              label-for="role_id"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="role_id"
                :options="getCodeOptions('role')"
                v-model="role"
                @input="changeSelect('role_id',$event)"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="职务"
              label-for="user_level"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                id="user_level"
                :options="getCodeOptions('user_level')"
                v-model="userLevel"
                @input="changeSelect('level', $event)"
                class="select-size-sm"
            >
            </v-select>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="所属团队"
              label-for="user_level"
              label-size="sm"
              :class="'mb-1 '+(user.deptartment_id == 8 ?'required':'')"
          >
            <v-select
                v-model="storeSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="label"
                :options="getCodeOptions('store')"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="所属仓库"
              label-for="user_level"
              label-size="sm"
              class="mb-1"
          >
            <v-select
                v-model="warehouseSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="label"
                :options="getCodeOptions('warehouse')"
                class="select-size-sm"
                :close-on-select="false"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="所属销售权团队"
              label-for="user_level"
              label-size="sm"
              class="mb-1 required"
          >
            <v-select
                v-model="purchaseTeamSelected"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                label="label"
                :options="getCodeOptions('purchase_team')"
                class="select-size-sm"
            />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="默认销售权团队"
                  label-for="user_level"
                  label-size="sm"
                  class="mb-1 required"
          >
            <v-select
                    v-model="defaultPurchaseTeamSelected"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="label"
                    :options="purchaseTeamSelected"
                    class="select-size-sm"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="集团工号"
              label-for="oneJobNum"
              label-size="sm"
              class="mb-1"

          >
            <b-form-input
                id="oneJobNum"
                size="sm"
                v-model="user.one_job_num"
                readonly
            />
          </b-form-group>
        </b-col>

        <!--
        <b-col md="3">
          <b-form-group
            label-cols="3"
            label-cols-lg="3"
            label="职位"
            label-for="level"
            label-size="sm"
            class="mb-1"
          >
            <b-form-input
              id="level"
              size="sm"
              v-model="user.level"
            />
          </b-form-group>
        </b-col>
        -->
        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              class="mr-1"
              @click="cancel"
          >
            取消
          </b-button>
          <b-button
              variant="outline-danger"
              @click="resetPwd"
          >
            重置密码
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BMediaBody,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import userStore from './userStore'
import attachmentStore from './../attachment/attachmentStore'
// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  get_upload_path,
  get_upload_url, isEmpty,isNumber
} from '@core/utils/filter'
import attachment from "@/router/routes/apps/attachment";

export default {
  components: {
    BCard,
    BMedia,
    BMediaBody,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
  },
  data() {
    return {
      id: ref(0),
      user: {},
      role: {},
      userLevel: {},
      departmentName: "",

      storeSelected: [],
      storeOption: [],
      warehouseSelected: [],
      warehouseOption: [],
      purchaseTeamSelected: [],
      defaultPurchaseTeamSelected:{},

    }
  },
  watch:{
    purchaseTeamSelected: {
      handler(newVal, oldVal) {
        if(!isEmpty(this.defaultPurchaseTeamSelected)){
          if (newVal.length === 0) {
            this.defaultPurchaseTeamSelected = {}
          } else {
            let result = false
            console.log(this.defaultPurchaseTeamSelected)
            for (let i = 0; i < newVal.length; i++) {
              if(isNumber(newVal[i].value)==this.defaultPurchaseTeamSelected.value){
                result = true;
              }
            }
            if(!result){
              this.defaultPurchaseTeamSelected = {}
            }
          }
        }
        this.$forceUpdate()
      },
      deep: true//深度
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('user')) store.registerModule('user', userStore)
    if (!store.hasModule('attachment')) store.registerModule('attachment', attachmentStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('user')) store.unregisterModule('user')
      if (store.hasModule('attachment')) store.unregisterModule('attachment')
    })

    const edit = function () {
      store.dispatch('user/edit', {id: this.id}).then(res => {
        this.user = res.data.data
        if (!isEmpty(this.user.ext)) {
          const store = this.user.ext.store
          for (let i = 0; i < store.length; i++) {
            this.storeSelected.push(getCode('store', store[i].store_id))
          }
          const warehouse = this.user.ext.warehouse
          for (let i = 0; i < warehouse.length; i++) {
            this.warehouseSelected.push(getCode('warehouse', warehouse[i].warehouse_id))
          }
          const purchaseTeam = this.user.ext.purchaseTeam
          for (let i = 0; i < purchaseTeam.length; i++) {
            this.purchaseTeamSelected.push(getCode('purchase_team', purchaseTeam[i].team_id))
          }
          this.defaultPurchaseTeamSelected.label=getCode('purchase_team', this.user.default_purchase_team_id).label
          this.defaultPurchaseTeamSelected.value=getCode('purchase_team', this.user.default_purchase_team_id).value

        }
        this.role = getCode('role', this.user.role_id)
        this.userLevel = getCode('user_level', this.user.level)
        this.departmentName = getCodeLabel("department", this.user.deptartment_id)
      })
    }

    const view = function () {
      this.edit()
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const save = function () {
      if (this.user.username == null || this.user.username == '') {
        toast.error("登录名不能为空!")
        return false
      }
      if (this.user.mobile == null || this.user.mobile == '') {
        toast.error("手机号不能为空!")
        return false
      }
      if (isEmpty(this.user.full_name)) {
        toast.error("真实姓名不能为空!")
        return false
      }
      if (isEmpty(this.user.role_id)) {
        toast.error("角色不能为空!")
        return false
      }
      if (isEmpty(this.user.level)) {
        toast.error("职务不能为空!")
        return false
      }
      if (this.user.deptartment_id !== '8' && this.user.deptartment_id !== 8 && isEmpty(this.user.email)) {
        toast.error("邮件不能为空!")
        return false
      }
      if (this.user.deptartment_id !== '8' && this.user.deptartment_id !== 8 && isEmpty(this.user.job_num)) {
        toast.error("工号不能为空!")
        return false
      }
      if (this.user.deptartment_id == 8 || this.user.deptartment_id == '8') {
        if (isEmpty(this.storeSelected)) {
          toast.error("所属团队不能为空!")
          return false
        }
        /*if (isEmpty(this.warehouseSelected)) {
          toast.error("所属仓库不能为空!")
          return false
        }*/
      }
      if (isEmpty(this.purchaseTeamSelected.length)) {
        toast.error("所属销售权团队不能为空!")
        return false
      }
      if (isEmpty(this.defaultPurchaseTeamSelected.value)) {
        toast.error("所属默认销售权团队不能为空!")
        return false
      }
      const storeArray = []
      const warehouseArray = []
      const purchaseTeamArray = []
      this.storeSelected.forEach((value, index, array) => {
        storeArray.push(value.value)
      })
      this.warehouseSelected.forEach((value, index, array) => {
        warehouseArray.push(value.value)
      })
      this.purchaseTeamSelected.forEach((value, index, array) => {
        purchaseTeamArray.push(value.value)
      })
      this.user.storeIds = storeArray.join(",")
      this.user.warehouseIds = warehouseArray.join(",")
      this.user.purchase_team_ids = purchaseTeamArray.join(",")
      this.user.default_purchase_team_id = this.defaultPurchaseTeamSelected.value
      store.dispatch('user/save', this.user).then(res => {
        if (res.data.code === 0) {
          toast.success('数据已保存!')
          this.$router.push({name: 'apps-user-list'});
        } else {
          toast.error(res.data.data)
        }
      })
    }

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const changeSelect = function (key, event) {
      this.user[key] = isEmpty(event) ? null : event.value
    }


    const changeAvatar = function (event) {
      const file = refInputEl.value.files[0]

      const params = new FormData()
      params.append('multiple', 'false')
      params.append('folder', 'user')
      params.append('file', file)
      params.append('object_type', 'user')
      params.append('object_id', this.user.id)

      store.dispatch('attachment/upload', params).then(res => {
        let attachment = res.data.data
        this.user.avatar = get_upload_path(attachment)
        toast.success('文件已上传!')
      })
    }

    const setDepartmentName = function (event) {
      if (event != undefined) {
        this.user.deptartment_id = event.value
      }
    }
    const resetPwd = function () {
      this.user.remark = "reset"
      store.dispatch('user/save', this.user).then(res => {
        if (res.data.code === 0) {
          toast.success('密码修改成功!')
          this.$router.push({name: 'apps-user-list'});
        } else {
          toast.error(res.data.data)
        }
      })
    }
    const setOneJobNum = function () {
      this.user.one_job_num = this.user.job_num
      this.$forceUpdate()
    }


    return {
      edit,
      view,
      cancel,
      save,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
      changeAvatar,

      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      changeSelect,
      get_upload_path,
      get_upload_url,
      setDepartmentName,
      resetPwd,
      setOneJobNum,
      isNumber
    }
  },
  created() {
    this.id = this.$route.query.id || 0;
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
